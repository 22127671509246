<template>
  <span>
    <v-btn class="ml-2" color="grey darken-3" dark alt="Help" @click="help = true">
      Help
      <v-icon right>
        mdi-help
      </v-icon>
    </v-btn>

    <v-dialog v-model="help" overlay-color="grey darken-3" width="800">
      <v-card>
        <v-card-title class="text-h5">Woodblock Suppliers</v-card-title>
        <v-card-subtitle class="text-h5">Help</v-card-subtitle>
        <v-card-text>
          <v-tabs v-model="helpTab">
            <v-tab key="search">Search</v-tab>
            <v-tab key="details">Details</v-tab>
            <v-tab key="book">Book</v-tab>
            <v-tab key="edit">Edit</v-tab>
            <v-tab key="ndas">NDAs</v-tab>
            <v-tab key="notes">Notes</v-tab>
          </v-tabs>
          <v-divider />
          <v-tabs-items v-model="helpTab">
            <v-tab-item key="search">
              <p></p>
              <p>To filter the table, enter any text into the search bar:
              <ul>
                <li><code>animation</code> will show only animators.</li>
              </ul>
              </p>
              <p>Enter names, addresses or tasks and software, e.g. <code>Berlin</code> or <code>Houdini</code>.<br>You
                may also use the column names <code>projects</code>, <code>level</code>, <code>rate</code> or
                <code>notes</code>.
              </p>
              <p>To search for multiple terms, separate them by spaces:
              <ul>
                <li><code>animation senior rate</code></li>
              </ul>
              </p>
              <p>To invert the search, prepend any term with an exclamation mark:
              <ul>
                <li><code>!level</code> shows all suppliers with no level rating.</li>
                <li><code>maya anim !junior !applicant level !ndas</code> shows all Maya animators that are not juniors or
                  applicants but do have a level rating and no NDAs, etc.</li>
              </ul>
              </p>
              <p>Toggle the "Show Blocked/Staff/Rates" checkboxes to filter the table accordingly.</p>
            </v-tab-item>
            <v-tab-item key="details">
              <p></p>
              <p>Select an entry from the table to see more details in the right pane.<br />
                It shows infos like street, phone and e-mail addresses, projects, NDAs and notes.</p>
              <p>If your browser is set up accordingly, clicking phone links will send them to your phone.</p>
              <p><code>Click</code> on task, software or project
                <v-chip class="ma-1 pa-2" color="grey lighten-1" small>chips</v-chip>
                to copy them into the search bar.
              </p>
              <p><code>Ctrl + Click</code> on task or software tags to add them to the end of the search string.</p>
            </v-tab-item>
            <v-tab-item key="book">
              <p></p>
              <p>To book a supplier, select them from the table and click
                <v-btn class="mx-1" color="grey darken-3" dark dense>Book</v-btn>.
              </p>
              <p>If they don't have a valid NDA or are in "blocked" state, a confirm dialog will pop up first.</p>
            </v-tab-item>
            <v-tab-item key="edit">
              <p></p>
              <p>Click
                <v-btn class="mx-1" color="grey darken-3" dark dense>New Entry</v-btn>
                or
                <v-btn class="mx-1" color="grey darken-3" dark dense>Edit</v-btn>
                to open the edit dialog.
              </p>
              <p>All required fields must be filled to enable the
                <v-btn class="light elevation-0">Save</v-btn>
                button.
              </p>
            </v-tab-item>
            <v-tab-item key="ndas">
              <p></p>
              <p>To create a new NDA for a supplier, select them from the table, expand the NDAs pane and click
                <v-btn class="mx-1" color="grey darken-3" dark dense>New NDA</v-btn>.
              </p>
              <p>New NDA PDF documents are created via the Google API in the background. This can take several seconds.
              </p>
              <p>Download an NDA and send it to suppliers via e-mail.</p>
              <p>When you receive a signed NDA back, upload it via the
                <v-btn class="mx-1" color="grey darken-3" dark dense>Upload Signed</v-btn> button.
              </p>
            </v-tab-item>
            <v-tab-item key="notes">
              <p></p>
              <p>Type supplier notes into the "Add note" field.</p>
              <p>Press <code>Enter</code> to insert a new line.</p>
              <p>Press <code>Ctrl + Enter</code> to save the note.</p>
              <p>Editing data or creating NDA documents will be logged in the notes pane as well.</p>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="help = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>

export default {
  data: () => ({
    help: false,
    helpTab: null
  })
}
</script>
