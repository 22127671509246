import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import { diffFrom, keys, stringToArray } from './helpers'


Vue.use(Vuex)

axios.interceptors.response.use(function (response) {
  // 200 normal response, pass through transparently
  return response;
}, function (error) {
  // catch 401 errors and reload the page, we assume this only happens when the user becomes logged out via api restart
  if (error.response.status === 401) {
    console.log('401 Received by axios interceptor, reloading app')
    window.location = '/'
    return
  } else {
    return Promise.reject(error)
  }
});


function entrySearchSummary(freelancer, notes) {
  let summary = [
    freelancer[keys.name],
    freelancer[keys.streetNo],
    freelancer[keys.zipCity],
    freelancer[keys.email1],
    freelancer[keys.email2],
    freelancer[keys.phone1],
    freelancer[keys.phone2],
    freelancer[keys.zipCity],
    freelancer[keys.level],
    freelancer[keys.level] ? 'level' : '',
    freelancer[keys.rate],
    freelancer[keys.rate] ? 'rate' : '',
    freelancer[keys.tasks],
    freelancer[keys.software],
    freelancer[keys.projects],
    freelancer.ndasFound ? 'ndas' : '',
    freelancer.notesCount > 0 ? 'notes' : '',
    freelancer[keys.projects] ? 'projects' : '',
    notesArraytoSearchString(notes)
  ].join().toLowerCase()
  summary = summary.replace(/,+/g, ",")
  return summary
}

function notesArraytoSearchString(notes) {
  return notes.filter(note => note.type === 'note')
    .map(note => note.note)
    .join('')
}

export const store = new Vuex.Store({
  state: {
    loggedIn: false,
    loginUrl: null,
    loadingCount: 0,
    entries: [],
    notes: [],
    errorMessage: '',
    errorVisible: false,
    showPersons: true,
    showCompanies: true,
    showStaff: false,
    showBlocked: false,
    showRates: true,
    vatDocuments: [],
    ndas: []
  },
  getters: {
    loading(state) {
      return state.loadingCount > 0
    },
    overview(state) {
      let entries = state.entries.map(
        entry => {
          const notes = store.getters.freelancerNotes(entry[keys.name])
          const vatDocuments = store.getters.freelancerVATDocuments(entry[keys.name])
          const ndas = store.getters.freelancerNDAs(entry[keys.name])
          entry.notesCount = notes.filter(note => note.type === 'note').length
          entry.ndasFound = ndas !== undefined

          return {
            ...entry,
            searchSummary: entrySearchSummary(entry, notes)
          }
        }
      )
      entries = entries.filter(entry => {
        const level = entry[keys.level].toLowerCase()
        if (entry[keys.gender].toLowerCase() === 'entity') {
          if (!state.showCompanies) {
            return false
          }
        } else {
          if (!state.showPersons) {
            return false
          }
        }
        if (level.indexOf('staff') >= 0) {
          if (!state.showStaff) {
            return false
          }
        }
        if (level === 'blocked') {
          if (!state.showBlocked) {
            return false
          }
        }
        return true
      })
      return entries
    },
    freelancerData(state) {
      return function (name) {
        const results = store.getters.overview.filter(
          freelancer => freelancer[keys.name] === name
        )
        if (results) {
          return results[0]
        }
        return null
      }
    },
    freelancerVATDocuments(state) {
      return function (name) {
        return store.state.vatDocuments[replaceDashesWithSpaces(name)]
      }
    },
    freelancerNDAs(state) {
      return function (name) {
        return store.state.ndas[replaceDashesWithSpaces(name)]
      }
    },
    freelancerNotes(state) {
      return function (freelancerName) {
        if (!freelancerName) {
          return []
        }
        let notes = state.notes.filter(
          note => note.freelancer.toLowerCase() === freelancerName.toLowerCase()
        )
        notes = notes.sort((a, b) => {
          if (a.timestamp < b.timestamp) return 1
          if (a.timestamp > b.timestamp) return -1
          return 0
        })
        return notes
      }
    },
    languageItems(state) {
      return ['DE', 'EN']
    },
    taskItems(state) {
      let items = []
      store.getters.overview.forEach(
        freelancer => items.push(...stringToArray(freelancer[keys.tasks]))
      )
      items = [... new Set(items)]
      items = items.sort()
      return items
    },
    softwareItems(state) {
      let items = []
      store.getters.overview.forEach(
        freelancer => items.push(...stringToArray(freelancer[keys.software]))
      )
      items = [... new Set(items)]
      items = items.sort()
      return items
    },
    projectItems(state) {
      let items = []
      store.getters.overview.forEach(
        freelancer => items.push(...stringToArray(freelancer[keys.projects]))
      )
      items = [... new Set(items)]
      items = items.filter(item => item.indexOf('_') > 0)
      items = items.sort()
      return items
    },
    levelItems(state) {
      return ['WB Staff', 'WB Star', 'Senior', 'Lead', 'Mid', 'Junior', 'Applicant', 'Unknown', 'Blocked']
    }
  },
  mutations: {
    SET_LOADING(state, loading) {
      state.loadingCount += loading ? 1 : -1
    },
    SET_LOGGED_IN(state, loggedIn) {
      state.loggedIn = loggedIn
    },
    SET_LOGIN_URL(state, url) {
      state.loginUrl = url
    },
    SHOW_ERROR(state, message) {
      console.log('mutate: ', message)
      state.errorMessage = message
      state.errorVisible = true
    },
    HIDE_ERROR(state) {
      state.errorMessage = ''
      state.errorVisible = false
    },
    SET_FREELANCERS(state, freelancers) {
      state.entries = freelancers.map(freelancer => {
        for (const [key, value] of Object.entries(keys)) {
          if (freelancer[value] === undefined) {
            freelancer[value] = ''
          }
        }

        if (freelancer[keys.link1] && !freelancer[keys.link1].includes(':')) {
          freelancer[keys.link1] = 'http://' + freelancer[keys.link1]
        }
        if (freelancer[keys.link2] && !freelancer[keys.link2].includes(':')) {
          freelancer[keys.link2] = 'http://' + freelancer[keys.link2]
        }
        if (freelancer[keys.link3] && !freelancer[keys.link3].includes(':')) {
          freelancer[keys.link3] = 'http://' + freelancer[keys.link3]
        }

        freelancer[keys.phone1] = freelancer[keys.phone1].replaceAll('+', '')
        freelancer[keys.phone2] = freelancer[keys.phone2].replaceAll('+', '')

        if (freelancer[keys.level].toLowerCase().indexOf('blacklist') >= 0) {
          freelancer[keys.level] = 'Blocked'
        }
        return freelancer
      })
    },
    SET_VAT_DOCUMENTS(state, vatDocuments) {
      let freelancerVATDocuments = {}
      for (const vatDocument of vatDocuments) {
        if (freelancerVATDocuments[vatDocument.freelancerName] === undefined) {
          freelancerVATDocuments[vatDocument.freelancerName] = []
        }
        freelancerVATDocuments[vatDocument.freelancerName].push(vatDocument)
      }
      state.vatDocuments = freelancerVATDocuments
    },
    SET_NDAS(state, ndas) {
      // Freelancer name to data mapping
      let freelancerNDAs = {}
      for (const nda of ndas) {
        if (freelancerNDAs[nda.freelancerName] === undefined) {
          freelancerNDAs[nda.freelancerName] = { ndas: [], hasValidNDA: false }
        }
        // Mark outdated NDAs
        freelancerNDAs[nda.freelancerName].ndas.push({
          ...nda,
          outdated: (Date.parse(nda.date) - new Date()) / 86400000 < (-356 * 2)
        })
      }
      for (let [freelancerName, data] of Object.entries(freelancerNDAs)) {
        // Find valid freelancer NDAs
        for (const nda of data.ndas) {
          if (nda.signed && !nda.outdated) {
            freelancerNDAs[nda.freelancerName].hasValidNDA = true
            break
          }
        }
        // Sort by date descending
        data.ndas = data.ndas.sort(
          (a, b) => {
            if (a.date < b.date) return 1
            if (a.date > b.date) return -1
            return 0
          }
        )
      }
      state.ndas = freelancerNDAs
    },
    APPEND_TEMP_NDA(state, freelancerName) {
      let ndas = { ...state.ndas }

      if (ndas[freelancerName] === undefined) {
        ndas[freelancerName] = { ndas: [], hasValidNDA: false }
      }

      ndas[freelancerName].ndas.unshift({
        id: Math.random().toString(),
        fileName: null,
        freelancerName,
        date: new Date(),
        signed: false
      })

      // Forcing Vue reactivity by setting a new object instead of just pushing to state.ndas in the code above
      state.ndas = ndas
    },
    SET_NOTES(state, notes) {
      state.notes = notes
    },
    ADD_NOTE(state, payload) {
      state.notes.push({
        editor: 'you',
        timestamp: new Date().toISOString(),
        ...payload
      })
    },
    UPDATE_FREELANCER(state, payload) {
      for (let freelancer of state.entries) {
        if (freelancer[keys.name] === payload[keys.name]) {
          for (const key in freelancer) {
            if (payload[key] == undefined) {
              console.log('Skipping missing key', key)
            }
            freelancer[key] = payload[key]
          }
          break
        }
      }
    },
  },
  actions: {
    fetchSession(context) {
      axios.get('/api/session', { withCredentials: true }).then(
        response => {
          context.commit('SET_LOGGED_IN', response.data.loggedIn)
        }
      )
        .catch(error => {
          console.log(error)
        })
      axios.get('/api/login-url').then(
        response => {
          context.commit('SET_LOGIN_URL', response.data.url)
        }
      ).catch(error => {
        console.log(error)
      })
    },
    fetchDataLoop(context) {
      this.dispatch('fetchFreelancers')
      this.dispatch('fetchNotes')
      this.dispatch('fetchVATDocuments')
      this.dispatch('fetchNDAs')
      setTimeout(() => {
        this.dispatch('fetchDataLoop')
      }, 1000 * 30)
    },
    fetchFreelancers(context) {
      context.commit('SET_LOADING', true)
      axios.get('/api/sheet/freelancers', { withCredentials: true })
        .then(response => {
          context.commit('SET_FREELANCERS', response.data)
          context.commit('SET_LOADING', false)
        })
        .catch(error => {
          console.log(error)
        })
    },
    fetchNotes(context) {
      context.commit('SET_LOADING', true)
      axios.get('/api/sheet/notes', { withCredentials: true })
        .then(response => {
          context.commit('SET_NOTES', response.data)
          context.commit('SET_LOADING', false)
        })
        .catch(error => {
          context.commit('SET_LOADING', false)
          console.log(error)
        })
    },
    async addNote(context, payload) {
      context.commit('SET_LOADING', true)
      context.commit('ADD_NOTE', payload)
      try {
        await axios.post(
          '/api/sheet/notes',
          payload,
          { withCredentials: true }
        )
        context.commit('SET_LOADING', false)
        return true
      }
      catch (e) {
        context.commit('SET_LOADING', false)
        return false
      }
    },
    fetchVATDocuments(context) {
      context.commit('SET_LOADING', true)
      axios.get(
        '/api/vat/documents',
        { withCredentials: true }
      )
        .then(response => {
          context.commit('SET_VAT_DOCUMENTS', response.data)
          context.commit('SET_LOADING', false)
        })
        .catch(error => {
          console.log(error)
        })
    },
    fetchNDAs(context) {
      context.commit('SET_LOADING', true)
      axios.get(
        '/api/ndas',
        { withCredentials: true }
      )
        .then(response => {
          context.commit('SET_NDAS', response.data)
          context.commit('SET_LOADING', false)
        })
        .catch(error => {
          console.log(error)
        })
    },
    async createNDA(context, freelancer) {
      context.commit('SET_LOADING', true)
      context.commit('APPEND_TEMP_NDA', freelancer[keys.name])
      axios.post(
        '/api/ndas/new',
        {
          fullName: freelancer[keys.name],
          firstName: replaceDashesWithSpaces(freelancer[keys.firstName]),
          lastName: replaceDashesWithSpaces(freelancer[keys.lastName]),
          streetNo: freelancer[keys.streetNo],
          zipCity: freelancer[keys.zipCity],
          country: freelancer[keys.country],
          language: freelancer[keys.language],
        },
        { withCredentials: true }
      )
        .then(response => {
          this.dispatch(
            'addNote',
            {
              freelancer: freelancer[keys.name],
              type: 'nda',
              note: 'created'
            }
          )
        })
        .catch((e) => {
          this.dispatch('showError', 'Server error creating NDA...')
        })
        .finally(() => {
          context.commit('SET_LOADING', false)
          this.dispatch('fetchNDAs')
        })
    },
    showError(context, message) {
      context.commit('SHOW_ERROR', message)
    },
    hideError(context) {
      context.commit('HIDE_ERROR')
    },
    async uploadVATDocument(context, data) {
      let formData = new FormData()
      formData.append('file', data.fileInput)
      formData.append('firstName', replaceDashesWithSpaces(data.firstName))
      formData.append('lastName', replaceDashesWithSpaces(data.lastName))
      formData.append('fullName', replaceDashesWithSpaces(data.fullName))
      return axios.post(
        '/api/vat/documents',
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
      )
        .then(response => {
          return response
        })
        .catch(error => {
          console.error(error)
          return null
        })
    },
    async uploadSignedNda(context, data) {

      let formData = new FormData()
      formData.append('file', data.fileInput)
      formData.append('firstName', replaceDashesWithSpaces(data.firstName))
      formData.append('lastName', replaceDashesWithSpaces(data.lastName))
      formData.append('fullName', replaceDashesWithSpaces(data.fullName))
      console.log('data', data)
      return axios.post(
        '/api/ndas/signed',
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
      )
        .then(response => {
          return response
        })
        .catch(error => {
          console.error(error)
          return null
        })
    },
    async addFreelancer(context, payload) {
      payload = joinFreelancerPayloadArrays({ ...payload })
      payload[keys.phone1] = cleanAndAppendPlus(payload[keys.phone1])
      payload[keys.phone2] = cleanAndAppendPlus(payload[keys.phone2])

      try {
        await axios.post(
          '/api/sheet/freelancers',
          payload,
          { withCredentials: true }
        )
        context.commit('UPDATE_FREELANCER', payload)
        context.commit('SET_LOADING', false)
        return true
      }
      catch (e) {
        context.commit('SET_LOADING', false)
        return false
      }
    },
    async updateFreelancer(context, payload) {
      payload = joinFreelancerPayloadArrays({ ...payload })
      payload[keys.phone1] = cleanAndAppendPlus(payload[keys.phone1])
      payload[keys.phone2] = cleanAndAppendPlus(payload[keys.phone2])

      const payloadDiff = diffFrom(this.getters.freelancerData(payload[keys.name]), payload)
      payloadDiff[keys.name] = payload[keys.name]
      try {
        await axios.put(
          '/api/sheet/freelancers',
          payloadDiff,
          { withCredentials: true }
        )
        context.commit('SET_LOADING', false)
        return true
      }
      catch (e) {
        context.commit('SET_LOADING', false)
        return false
      }
    }
  },
})

// we need to ensure exactly one '+ before phone numbers, so clean and add them here
function cleanAndAppendPlus(phoneNumber) {
  if (phoneNumber) {
    phoneNumber = phoneNumber.replaceAll("'", '') // ' is an escape character for sheets
    phoneNumber = phoneNumber.replaceAll('+', '')
    phoneNumber = "'+" + phoneNumber
  }
  return phoneNumber
}

function joinFreelancerPayloadArrays(payload) {
  payload[keys.tasks] = payload.tasksArray.join(', ')
  payload[keys.software] = payload.softwareArray.join(', ')
  payload[keys.projects] = payload.projectsArray.join(', ')
  payload.searchSummary = undefined
  payload[keys.level] = payload[keys.level].replace('Unknown', '')
  return payload
}

function replaceDashesWithSpaces(input) {
  return input.replaceAll('-', ' ')
}