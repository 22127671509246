var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticStyle:{"cursor":"default"},attrs:{"headers":_vm.headers,"items":_vm.viewData,"item-key":"row","sort-by":"Full Name","footer-props":{
  'items-per-page-options': [20, 50, 100, -1],
},"items-per-page":50,"search":_vm.search,"loading":_vm.$store.getters.loading,"loader-height":"3","single-select":"","custom-filter":_vm.customFilter,"dense":"","fixed-header":"","height":"88vh","no-data-text":_vm.noDataText},on:{"click:row":_vm.select},scopedSlots:_vm._u([{key:"item.notesCount",fn:function(ref){
var item = ref.item;
return [(item.notesCount)?_c('v-chip',{attrs:{"color":"light-grey","small":""}},[_vm._v(" "+_vm._s(item.notesCount)+" ")]):_vm._e()]}},{key:"item.projectsArray",fn:function(ref){
var item = ref.item;
return [(item.projectsArray.length)?_c('div',[_vm._l((item.projectsArray.slice(0, 1)),function(project){return _c('v-chip',{key:project.id,staticClass:"custom-chip mt-1 mb-1 mr-1 pa-2 pt-0 pb-0",attrs:{"color":"light-grey","small":""}},[_vm._v(" "+_vm._s(project)+" ")])}),(item.projectsArray.length > 1)?_c('span',{staticClass:"grey--text"},[_vm._v(" +"+_vm._s(item.projectsArray.length - 1)+" ")]):_vm._e()],2):_vm._e()]}},{key:"item.niceRate",fn:function(ref){
var item = ref.item;
return [(item.niceRate)?_c('div',[(_vm.$store.state.showRates)?_c('span',[_vm._v(" "+_vm._s(item.niceRate)+" ")]):_c('span',{staticClass:"grey--text"},[_vm._v(" hidden ")])]):_vm._e()]}},{key:"item.tasksArray",fn:function(ref){
var item = ref.item;
return [(item.tasksArray.length)?_c('div',[_vm._l((item.tasksArray.slice(0, 2)),function(task){return _c('v-chip',{key:task,staticClass:"custom-chip mt-1 mb-1 mr-1 pa-2 pt-0 pb-0",attrs:{"color":"light-grey","small":""}},[_vm._v(" "+_vm._s(task)+" ")])}),(item.tasksArray.length > 2)?_c('span',{staticClass:"grey--text"},[_vm._v(" +"+_vm._s(item.tasksArray.length - 2)+" ")]):_vm._e()],2):_vm._e()]}},{key:"item.softwareArray",fn:function(ref){
var item = ref.item;
return [(item.softwareArray.length)?_c('div',[_vm._l((item.softwareArray.slice(0, 2)),function(software){return _c('v-chip',{key:software,staticClass:"custom-chip mt-1 mb-1 mr-1 pa-2 pt-0 pb-0",attrs:{"color":"light-gray","small":""}},[_vm._v(" "+_vm._s(software)+" ")])}),(item.softwareArray.length > 2)?_c('span',{staticClass:"grey--text"},[_vm._v(" +"+_vm._s(item.softwareArray.length - 2)+" ")]):_vm._e()],2):_vm._e()]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})}
var staticRenderFns = []

export { render, staticRenderFns }