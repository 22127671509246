<template>
  <v-card flat color="#f0f0f0" class="mx-4">
    <form @submit.prevent>
      <v-textarea v-model="newNote" append-icon="mdi-send" class="mx-0" label="Add note" rows="1" filled dense
        :disabled="sending" @click:append="addNote" @keydown.enter="enterPressed"></v-textarea>
    </form>

    <v-list>
      <div v-for="(note, index) in notes" :key="note.row">
        <div v-if="note.type === 'add'" flat>
          <p class="caption grey--text text-center my-1">
            {{ note.editor }} added {{ note.timestamp | moment("from", "now") }}
          </p>
        </div>

        <div v-else-if="note.type === 'edit'" flat>
          <p class="caption grey--text text-center my-1">
            {{ note.editor }} edited {{ note.timestamp | moment("from", "now") }}
          </p>
        </div>

        <div v-else-if="note.type === 'vat'">
          <p class="caption grey--text text-center my-1">
            {{ note.editor }} uploaded VAT document {{ note.timestamp | moment("from", "now") }}
          </p>
        </div>

        <div v-else-if="note.type === 'nda'">
          <p class="caption grey--text text-center my-1" v-if="note.note === 'created'">
            {{ note.editor }} created new NDA {{ note.timestamp | moment("from", "now") }}
          </p>
          <p class="caption grey--text text-center my-1" v-if="note.note === 'signed'">
            {{ note.editor }} uploaded signed NDA {{ note.timestamp | moment("from", "now") }}
          </p>
        </div>

        <v-list-item v-else>
          <v-list-item-content>
            <span style="white-space: pre-wrap" v-linkified>{{ note.note }}
            </span>
          </v-list-item-content>

          <v-list-item-action>
            <v-list-item-action-text>{{ note.timestamp | moment("from", "now") }}</v-list-item-action-text>
            <v-list-item-action-text v-text="note.editor"></v-list-item-action-text>
            <v-spacer></v-spacer>
          </v-list-item-action>
        </v-list-item>

        <v-divider v-if="index < notes.length - 1" :key="index" light></v-divider>
      </div>
    </v-list>
  </v-card>
</template>

<script>
export default {
  props: [
    'freelancerName'
  ],
  data: () => ({
    newNote: '',
    sending: false
  }),
  computed: {
    notes() {
      return this.$store.getters.freelancerNotes(this.freelancerName)
    }
  },
  methods: {
    addNote: async function () {
      if (!this.newNote) {
        return
      }
      this.sending = true
      let success = await this.$store.dispatch('addNote', {
        freelancer: this.freelancerName,
        type: 'note',
        note: this.newNote
      })
      if (success) {
        this.newNote = ''
      }
      this.sending = false
    },
    enterPressed(event) {
      if (event.ctrlKey) {
        event.preventDefault()
        this.addNote()
      }
    }
  },
  watch: {
    freelancerName: function () {
      this.newNote = ''
    }
  }
}
</script>
