<template>
  <v-card v-if="freelancer" flat color="#f0f0f0">
    <v-card-title class="pa-0">
      <v-container>
        <v-row cols="12">
          <v-col class="mx-0" cols="10">
            <span class="mx-1">
              {{ freelancerName }}
            </span>
          </v-col>
          <v-col class="mx-0 grey--text text-right" cols="2">
            {{ freelancer[keys.language] || "" }}
          </v-col>
        </v-row>
        <v-row class="mx-0 mt-2 mb-1">
          <v-btn class="mx-1" color="grey darken-3" dark target="_blank" width="90" @click="bookFreelancerClicked">
            Book
          </v-btn>

          <v-dialog v-model="bookBlockedFreelancerDialog" overlay-color="grey darken-3" width="400">
            <v-card>
              <v-card-title class="text-h5"> Supplier blocked </v-card-title>
              <v-card-text>
                <v-row class="mx-0 my-1">{{ freelancerName }} has the level "Blocked".</v-row>
                <v-row class="mx-0 my-1">Are you sure you want to book?</v-row>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="bookBlockedFreelancerDialogCancel"> Cancel </v-btn>
                <v-btn text @click="bookBlockedFreelancerDialogConfirm"> Confirm </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="freelancerNdaMissingDialog" overlay-color="grey darken-3" width="400">
            <v-card>
              <v-card-title class="text-h5"> Supplier NDA missing </v-card-title>
              <v-card-text>
                <v-row class="mx-0 my-1">{{ freelancerName }} has no recent signed NDA.</v-row>
                <v-row class="mx-0 my-1">Create one or continue anyways?</v-row>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="freelancerNdaMissingDialogCancel"> Cancel </v-btn>
                <v-btn text @click="freelancerNdaMissingDialogContinue"> Continue </v-btn>
                <v-btn text @click="freelancerNdaMissingDialogCreate"> Create NDA </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="ndaValuesMissingDialog" overlay-color="grey darken-3">
            <v-card>
              <v-card-title class="text-h5"> Supplier NDA data is incomplete </v-card-title>
              <v-card-text>
                <v-row cols="12">
                  <v-col cols="3">Full Name</v-col>
                  <v-col cols="9">{{ freelancerName }}</v-col>
                </v-row>
                <v-row cols="12">
                  <v-col cols="3">Address</v-col>
                  <v-col cols="9" v-if="freelancer[keys.streetNo]">{{ freelancer[keys.streetNo] }}</v-col>
                  <v-col cols="9" v-else class="black--text font-weight-medium">missing</v-col>
                </v-row>
                <v-row cols="12">
                  <v-col cols="3">Zip, City</v-col>
                  <v-col cols="9" v-if="freelancer[keys.zipCity]">{{ freelancer[keys.zipCity] }}</v-col>
                  <v-col cols="9" v-else class="black--text font-weight-medium">missing</v-col>
                </v-row>
                <v-row cols="12">
                  <v-col cols="3">Country</v-col>
                  <v-col cols="9" v-if="freelancer[keys.country]">{{ freelancer[keys.country] }}</v-col>
                  <v-col cols="9" v-else class="black--text font-weight-medium">missing</v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    Continue creating an NDA anyways?
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="ndaValuesMissingDialogAbort"> Abort </v-btn>
                <v-btn text @click="ndaValuesMissingDialogContinue"> Continue </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-spacer />

          <FreelancerEdit buttonLabel="Edit" :freelancerName="freelancerName" />
        </v-row>
      </v-container>
    </v-card-title>

    <v-card-text class="pa-0">

      <v-divider />

      <v-list class="pa-0">
        <v-list-group :value="expandProfile">
          <template v-slot:activator>
            <v-list-item-title>Profile</v-list-item-title>
          </template>

          <v-list-item class="mb-3 mt-1">
            <v-container fluid>
              <v-row v-if="freelancer[keys.streetNo]">
                {{ freelancer[keys.streetNo] }}
              </v-row>

              <v-row v-if="freelancer[keys.zipCity]">
                {{ freelancer[keys.zipCity] }}
              </v-row>

              <v-row v-if="freelancer[keys.country]">
                {{ freelancer[keys.country] }}
              </v-row>

              <v-row v-if="freelancer[keys.phone1] || freelancer[keys.email1]" class="mt-6"></v-row>

              <v-row v-if="freelancer[keys.phone1]">
                <a :href="'tel:+' + freelancer[keys.phone1]" class="text-decoration-none">
                  +{{ freelancer[keys.phone1] }}
                </a>
              </v-row>

              <v-row v-if="freelancer[keys.phone2]">
                <a :href="'tel:+' + freelancer[keys.phone2]" class="text-decoration-none">
                  +{{ freelancer[keys.phone2] }}
                </a>
              </v-row>

              <v-row v-if="freelancer[keys.email1]">
                <a :href="'mailto:' + freelancer[keys.email1]" target="_blank" class="text-decoration-none">
                  {{ freelancer[keys.email1] }}
                </a>
              </v-row>

              <v-row v-if="freelancer[keys.email2]">
                <a :href="'mailto:' + freelancer[keys.email2]" target="_blank" class="text-decoration-none">
                  {{ freelancer[keys.email2] }}
                </a>
              </v-row>

              <v-row v-if="freelancer[keys.link1] ||
    freelancer[keys.link2] ||
    freelancer[keys.link3]
    " class="mt-6"></v-row>

              <v-row v-if="freelancer[keys.link1]">
                <!-- <v-icon
                                    class="mr-2"
                                    small
                                >
                                    mdi-link-variant
                                </v-icon> -->
                <a :href="freelancer[keys.link1]" target="_blank" class="text-decoration-none">
                  {{ freelancer[keys.link1] }}
                </a>
              </v-row>

              <v-row v-if="freelancer[keys.link2]">
                <!-- <v-icon
                                    class="mr-2"
                                    small
                                >
                                    mdi-link-variant
                                </v-icon> -->
                <a :href="freelancer[keys.link2]" target="_blank" class="text-decoration-none">
                  {{ freelancer[keys.link2] }}
                </a>
              </v-row>

              <v-row v-if="freelancer[keys.link3]">
                <!-- <v-icon
                                    class="mr-2"
                                    small
                                >
                                    mdi-link-variant
                                </v-icon> -->
                <a :href="freelancer[keys.link3]" target="_blank" class="text-decoration-none">
                  {{ freelancer[keys.link3] }}
                </a>
              </v-row>

              <v-row v-if="freelancer[keys.vatId]" class="py-3">
                VAT-Id: {{ freelancer[keys.vatId] }}
              </v-row>

              <v-row class="mt-6"></v-row>

              <v-row>
                <!-- <v-icon
                                    v-if="freelancer.blacklisted"
                                    bold
                                    class="mr-2"
                                    small
                                >
                                    mdi-alert-circle
                                </v-icon>
                                <v-icon
                                    v-else
                                    class="mr-2"
                                    small
                                >
                                    mdi-arm-flex
                                </v-icon> -->

                <span v-if="freelancer[keys.level]">
                  {{ freelancer[keys.level] }}
                </span>
                <span v-else> Level unknown </span>
              </v-row>

              <v-row>
                <!-- <v-icon
                                    small
                                    class="mr-2"
                                >
                                    mdi-cash-multiple
                                </v-icon> -->
                <span v-if="$store.state.showRates || !freelancer[keys.rate]">
                  {{ freelancer[keys.rate] || "Rate unknown" }}
                </span>
                <span v-else> Rates hidden </span>
              </v-row>

              <v-row class="mt-6"></v-row>

              <v-row>
                <v-chip v-for="task of freelancer.tasksArray" :key="task" class="mt-1 mb-1 mr-1 pa-2 pt-0 pb-0"
                  color="grey lighten-1" small @click.exact="emitSearch(task, false)"
                  @click.ctrl.exact="emitSearch(task, true)" @click.shift.exact="emitSearch(task, true)">
                  {{ task }}
                </v-chip>
              </v-row>

              <v-row>
                <v-chip v-for="software of freelancer.softwareArray" :key="software"
                  class="mt-1 mb-1 mr-1 pa-2 pt-0 pb-0" color="grey lighten-2" small
                  @click.exact="emitSearch(software, false)" @click.ctrl.exact="emitSearch(software, true)"
                  @click.shift.exact="emitSearch(software, true)">
                  {{ software }}
                </v-chip>
              </v-row>
            </v-container>
          </v-list-item>
        </v-list-group>
      </v-list>

      <v-divider />

      <v-list class="pa-0">
        <v-list-group :value="expandProjects">
          <template v-slot:activator>
            <v-list-item-title>Projects</v-list-item-title>
          </template>
          <v-list-item class="mb-3 mt-1">
            <v-container>
              <v-row>
                <v-col>
                  <v-row v-for="project of freelancer.projectsArray" :key="project">
                    <v-chip color="grey lighten-1" small label class="mb-1" @click="emitSearch(project)">
                      {{ project }}
                    </v-chip>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-list-item>
        </v-list-group>
      </v-list>

      <v-divider />

      <v-list class="pa-0">
        <v-list-group :value="expandNDAs">
          <template v-slot:activator>
            <v-list-item-title>NDAs</v-list-item-title>
          </template>

          <v-row class="pt-4 pl-7 pr-6">
            <v-btn color="grey darken-3" dark target="_blank" @click="checkNDAfields">
              New NDA
            </v-btn>
            <v-spacer />
            <UploadSignedNDA :freelancerName="freelancerName" />
          </v-row>

          <v-list-item class="pa-0 ma-0 mb-0 mt-3" two-line>
            <v-container v-if="ndas">
              <v-row v-for="nda of ndas.ndas" :key="nda.id" class="px-4">
                <span>
                  <a v-if="nda.fileName" :href="`https://drive.google.com/file/d/${nda.id}/view`" target="_blank"
                    class="text-decoration-none">
                    {{ nda.fileName }}
                  </a>
                  <span v-else>Creating...</span>
                </span>

                <span v-if="nda.fileName" class="mx-3">
                  <v-tooltip v-if="nda.signed && !nda.outdated" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon color="green" v-bind="attrs" v-on="on"> mdi-check </v-icon>
                    </template>
                    <span>Signed and less than 2 years old</span>
                  </v-tooltip>

                  <v-tooltip v-else-if="nda.signed && nda.outdated" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon color="red" v-bind="attrs" v-on="on">
                        mdi-clock-alert-outline
                      </v-icon>
                    </template>
                    <span>Signed NDA document is older than 2 years</span>
                  </v-tooltip>

                  <v-tooltip v-else-if="!nda.outdated" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon color="red" v-bind="attrs" v-on="on">
                        mdi-file-sign
                      </v-icon>
                    </template>
                    <span>NDA document needs to be signed</span>
                  </v-tooltip>

                  <v-tooltip v-else bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon color="red" v-bind="attrs" v-on="on"> mdi-alert </v-icon>
                    </template>
                    <span>NDA document not signed and older than 2 years</span>
                  </v-tooltip>
                </span>
              </v-row>
            </v-container>

            <v-container v-else class="mx-1 my-0"> No NDAs found. </v-container>
          </v-list-item>
          <!-- <v-list-item>
            <a class="text-decoration-none" target="_blank"
              href="https://drive.google.com/drive/folders/1lx8fYh8p_Dg66DCbqWEHCgGI5W45WtgA">
              Open NDAs folder
            </a>
          </v-list-item> -->
        </v-list-group>
      </v-list>

      <v-divider />

      <v-list class="pa-0">
        <v-list-group :value="expandVat">
          <template v-slot:activator>
            <v-list-item-title>VAT Documents</v-list-item-title>
          </template>

          <v-row class="py-4 pr-6">
            <v-spacer />
            <UploadVATDocument :freelancerName="freelancerName" />
          </v-row>

          <v-list-item class="mb-3 mt-1">
            <v-container fluid>
              <template v-if="vatDocuments">
                <v-row v-for="doc of vatDocuments" :key="doc.id" class="py-0.5">
                  <span>
                    <a v-if="doc.fileName" :href="`https://drive.google.com/file/d/${doc.id}/view`" target="_blank"
                      class="text-decoration-none">
                      {{ doc.fileName }}
                    </a>
                  </span>
                </v-row>
              </template>
              <v-row v-else>
                No documents found.
              </v-row>
            </v-container>
          </v-list-item>
        </v-list-group>
      </v-list>

      <v-divider />

      <v-list class="pa-0">
        <v-list-group :value="expandNotes">
          <template v-slot:activator>
            <v-list-item-title>Notes</v-list-item-title>
          </template>
          <v-list-item class="pa-0 ma-0 mb-3 mt-1">
            <v-container class="pa-0 ma-0">
              <PanelNotes :freelancerName="freelancerName" />
            </v-container>
          </v-list-item>
        </v-list-group>
      </v-list>

      <v-divider />

    </v-card-text>
  </v-card>
</template>

<script>
import { keys, splitZipCity, stringToArray } from "../helpers"
import FreelancerEdit from "./FreelancerEdit.vue"
import UploadSignedNDA from "./UploadSignedNDA.vue"
import UploadVATDocument from "./UploadVATDocument.vue"
import PanelNotes from "./PanelNotes.vue"

export default {
  props: ["freelancerName"],
  emits: ["searchAppend", "searchReplace"],
  components: {
    FreelancerEdit,
    UploadVATDocument,
    UploadSignedNDA,
    PanelNotes,
  },
  data: () => ({
    expandProfile: true,
    expandProjects: false,
    expandVat: false,
    expandNDAs: false,
    expandNotes: true,
    newNote: "",
    sending: false,
    keys: { ...keys },
    bookBlockedFreelancerDialog: false,
    freelancerNdaMissingDialog: false,
    ndaValuesMissingDialog: false,
  }),
  computed: {
    freelancer() {
      if (!this.freelancerName) {
        return null
      }
      const data = this.$store.getters.freelancerData(this.freelancerName)
      if (data) {
        const [zip, city] = splitZipCity(data[keys.zipCity])
        return {
          ...data,
          zip,
          city,
          projectsArray: stringToArray(data[keys.projects]),
          tasksArray: stringToArray(data[keys.tasks]),
          softwareArray: stringToArray(data[keys.software]),
          blacklisted: data[keys.level].toLowerCase().indexOf("blacklist") >= 0,
        }
      }
      return null
    },
    vatDocuments() {
      return this.$store.getters.freelancerVATDocuments(this.freelancerName)
    },
    ndas() {
      return this.$store.getters.freelancerNDAs(this.freelancerName)
    },
  },
  methods: {
    bookFreelancerClicked() {
      if (!this.freelancer) {
        console.log("Invalid freelancer object")
        return
      }
      if (this.freelancer[keys.level].indexOf("Blocked") >= 0) {
        this.bookBlockedFreelancerDialog = true
      } else {
        this.bookFreelancerCheckNDA()
      }
    },
    bookFreelancerCheckNDA() {
      if (!this.ndas || !this.ndas.hasValidNDA) {
        this.freelancerNdaMissingDialog = true
      } else {
        this.bookFreelancer()
      }
    },
    async checkNDAfields() {
      if (!this.freelancer[keys.streetNo] || !this.freelancer[keys.zipCity] || !this.freelancer[keys.country]) {
        this.ndaValuesMissingDialog = true
      } else {
        this.createNDA()
      }
    },
    async createNDA() {
      this.expandNDAs = true
      await this.$store.dispatch("createNDA", this.freelancer)
    },
    bookBlockedFreelancerDialogCancel() {
      this.bookBlockedFreelancerDialog = false
    },
    bookBlockedFreelancerDialogConfirm() {
      this.bookBlockedFreelancerDialog = false
      this.bookFreelancerCheckNDA()
    },
    freelancerNdaMissingDialogCancel() {
      this.freelancerNdaMissingDialog = false
    },
    freelancerNdaMissingDialogCreate() {
      this.checkNDAfields()
      this.freelancerNdaMissingDialog = false
    },
    freelancerNdaMissingDialogContinue() {
      this.freelancerNdaMissingDialog = false
      this.bookFreelancer()
    },
    ndaValuesMissingDialogAbort() {
      this.ndaValuesMissingDialog = false
    },
    ndaValuesMissingDialogContinue() {
      this.ndaValuesMissingDialog = false
      this.createNDA()
    },
    bookFreelancer() {
      const url =
        "https://docs.google.com/forms/d/e/1FAIpQLSdzFWhNNUi4uT2eztBuWrbMkeLmj6JY2izr5_MefvhdFu71uw/viewform?entry.951258083=" +
        this.freelancerName
      window.open(url, "_blank")
    },
    emitSearch(searchText, shift) {
      this.$emit(shift ? "searchAppend" : "searchReplace", searchText)
    },
  },
}
</script>
