/**
 * Frontend server
 */

import Vue from 'vue'
import App from './App.vue'
import Vuetify from 'vuetify/lib/framework'
import linkify from 'vue-linkify'
import { store } from './store'

Vue.config.productionTip = true
Vue.config.performance = process.env.NODE_ENV != 'production'

Vue.use(Vuetify)
Vue.directive('linkified', linkify)
Vue.use(require('vue-moment'))

let vuetify = new Vuetify({})

new Vue({
  vuetify,
  store: store,
  render: h => h(App)
}).$mount('#app')
