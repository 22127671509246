<template>
  <v-dialog v-model="dialog" :persistent="persistent" width=920>
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" color="grey darken-3" dark target="_blank">
        {{ buttonLabel }}
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        <span v-if="newFreelancer">
          New Entry
        </span>
        <span v-else class="text-h5 my-2">
          {{ formData[keys.name] }}
        </span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <span v-if="newFreelancer">
            <v-row cols="12">
              <v-col cols=4 class="pa-0 pr-3">
                <v-select label="Identity (required)" v-model="gender" :items="['Female', 'Male', 'Entity']"
                  :disabled="sending" />
              </v-col>
              <v-col cols=4 class="pa-0 pr-3">
                <v-text-field v-if="gender === 'Entity'" label="Company Name (required)" v-model="firstName"
                  :disabled="sending" />
                <v-text-field v-else label="First Name (required)" v-model="firstName" :disabled="sending" />
              </v-col>
              <v-col cols=4 class="pa-0 pl-3">
                <v-text-field v-if="gender === 'Entity'" label="Legal Form (required)" v-model="lastName"
                  :disabled="sending" />
                <v-text-field v-else label="Last Name (required)" v-model="lastName" :disabled="sending" />
              </v-col>
            </v-row>
          </span>

          <v-row cols=12>
            <v-col cols=4 class="pa-0 pr-3">
              <v-text-field label="Street" v-model="formData.street" :disabled="sending" dense />
            </v-col>
            <v-col cols=2 class="pa-0 pr-3">
              <v-text-field label="No" v-model="formData.number" :disabled="sending" dense />
            </v-col>
            <v-col cols=2 class="pa-0 px-3">
              <v-text-field label="ZIP" v-model="formData.zip" :disabled="sending" dense />
            </v-col>

            <v-col cols=4 class="pa-0 pl-3">
              <v-text-field label="City" v-model="formData.city" :disabled="sending" dense />
            </v-col>
          </v-row>

          <v-row>
            <v-col class="pa-0 pr-3">
              <v-text-field label="Country" v-model="formData[keys.country]" :disabled="sending" dense />
            </v-col>
            <v-col class="pa-0 pl-3">
              <v-select label="Language (required)" :items="$store.getters.languageItems"
                v-model="formData[keys.language]" :disabled="sending" dense />
            </v-col>
          </v-row>

          <v-row class="pt-8">
            <v-col class="pa-0 pr-3">
              <v-text-field label="Phone 1" v-model="formData[keys.phone1]" prefix="+" :rules="[rules.phone]"
                :disabled="sending" dense />
            </v-col>
            <v-col class="pa-0 pl-3">
              <v-text-field label="Phone 2" v-model="formData[keys.phone2]" prefix="+" :rules="[rules.phone]"
                :disabled="sending" dense />
            </v-col>
          </v-row>

          <v-row>
            <v-col class="pa-0 pr-3">
              <v-text-field label="E-Mail 1 (required)" v-model="formData[keys.email1]" :rules="[rules.email]"
                :disabled="sending" dense />
            </v-col>
            <v-col class="pa-0 pl-3">
              <v-text-field label="E-Mail 2" v-model="formData[keys.email2]" :rules="[rules.email]" :disabled="sending"
                dense />
            </v-col>
          </v-row>

          <v-row>
            <v-col class="pa-0 pr-3">
              <v-text-field label="Link 1" v-model="formData[keys.link1]" :disabled="sending" dense />
            </v-col>
            <v-col class="pa-0 px-3">
              <v-text-field label="Link 2" v-model="formData[keys.link2]" :disabled="sending" dense />
            </v-col>
            <v-col class="pa-0 pl-3">
              <v-text-field label="Link 3" v-model="formData[keys.link3]" :disabled="sending" dense />
            </v-col>
          </v-row>

          <v-row class="pt-8">
            <v-col class="pa-0 pr-3">
              <v-select label="Level" v-model="formData[keys.level]" :items="$store.getters.levelItems"
                :disabled="sending" dense />
            </v-col>
            <v-col class="pa-0 pl-3">
              <v-text-field label="Rate" v-model="formData[keys.rate]" :disabled="sending" dense />
            </v-col>
          </v-row>

          <v-row class="pt-8">
            <v-col></v-col>
            <v-col class="pa-0">
              <v-text-field label="VAT-Id" v-model="formData[keys.vatId]" :disabled="sending" dense />
            </v-col>
          </v-row>

          <v-row class="pt-8">
            <v-combobox label="Tasks" v-model="formData.tasksArray" :items="$store.getters.taskItems"
              :rules="[rules.tasks]" hide-selected multiple small-chips deletable-chips :disabled="sending" dense />
          </v-row>

          <v-row>
            <v-combobox label="Software" v-model="formData.softwareArray" :items="$store.getters.softwareItems"
              hide-selected multiple small-chips deletable-chips :disabled="sending" dense />
          </v-row>

          <v-row>
            <v-combobox label="Projects" v-model="formData.projectsArray" :items="$store.getters.projectItems"
              hide-selected multiple small-chips deletable-chips :disabled="sending" dense>
              <template v-slot:selection="data">
                <v-chip :key="JSON.stringify(data.item)" v-bind="data.attrs" :input-value="data.selected"
                  :disabled="data.disabled" @click:close="data.parent.selectItem(data.item)" small label close>
                  {{ data.item }}
                </v-chip>
              </template>
            </v-combobox>
          </v-row>

        </v-container>
      </v-card-text>

      <v-alert v-if="nameError" color="warning" dark class="rounded-0">
        Name already exists in database
      </v-alert>

      <v-alert v-if="networkError" color="error" dark class="rounded-0">
        Network error
      </v-alert>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="dialog = false" :disabled="sending">
          Cancel
        </v-btn>
        <v-btn color="blue darken-1" text @click="saveChanges"
          :disabled="sending || (newFreelancer && ((!firstName && !lastName) || !gender || !formData[keys.language] || !formData[keys.email1]))">
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { keys, splitStreetNo, splitZipCity, stringToArray, stripSpaces } from "../helpers"

export default {
  props: {
    buttonLabel: String,
    freelancerName: {
      type: String,
      default: ""
    },
    newFreelancer: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    dialog: false,
    rules: {
      email: value => {
        const pattern = /^(([^<>()[\]\\.,:\s@"]+(\.[^<>()[\]\\.,:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return !value || pattern.test(value) || "Invalid e-mail."
      },
      phone: value => {
        const pattern = /^\d{2,20}{\s?|\d+}*/
        return !value || pattern.test(value) || "Invalid format."
      },
      tasks: value => {
        return value.length <= 6 || "Not more than 6 tasks allowed."
      }
    },
    keys: keys,
    formData: {},
    formDataBackup: {},
    firstName: '',
    lastName: '',
    gender: null,
    networkError: false,
    nameError: false,
    sending: false,
  }),
  computed: {
    persistent() {
      return JSON.stringify(this.formData) != JSON.stringify(this.formDataBackup) || this.sending
    }
  },
  methods: {
    updateData() {
      this.networkError = false
      this.nameError = false
      let data = {}
      if (this.newFreelancer) {
        for (const [key, value] of Object.entries(keys)) {
          data[value] = ''
          this.firstName = ''
          this.lastName = ''
          this.gender = ''
          this.title = ''
          this.salutation = ''
        }
      } else {
        data = this.$store.getters.freelancerData(this.freelancerName)
        data = JSON.parse(JSON.stringify(data)) || {}
      }
      let [street, number] = splitStreetNo(data[keys.streetNo])
      let [zip, city] = splitZipCity(data[keys.zipCity])

      if (data[keys.tasks] === undefined || data[keys.software] === undefined || data[keys.projects] === undefined) {
        console.log('array fields undefined on object: ', data)
      }
      this.formData = {
        ...data,
        street,
        number,
        zip,
        city,
        tasksArray: stringToArray(data[keys.tasks]),
        softwareArray: stringToArray(data[keys.software]),
        projectsArray: stringToArray(data[keys.projects]),
      }
      this.formDataBackup = { ...this.formData }
    },
    autoTitle(gender, language) {
      if (gender === 'Female') return language === 'DE' ? 'Frau' : 'Madam'
      if (gender === 'Male') return language === 'DE' ? 'Herr' : 'Mister'
      if (gender === 'Entity') return language === 'DE' ? 'Firma' : 'Company'
    },
    autoSalutation(gender, language) {
      if (gender === 'Female') return language === 'DE' ? 'Liebe' : 'Dear'
      if (gender === 'Male') return language === 'DE' ? 'Lieber' : 'Dear'
      if (gender === 'Entity') return language === 'DE' ? 'Sehr geehrte' : 'Dear'
    },
    autoSalutationEmail(gender, language, firstName) {
      if (gender === 'Female') return language === 'DE' ? `Liebe ${firstName},` : `Dear ${firstName},`
      if (gender === 'Male') return language === 'DE' ? `Lieber ${firstName},` : `Dear ${firstName},`
      if (gender === 'Entity') return language === 'DE' ? 'Sehr geehrte Damen und Herren,' : 'Dear Sir or Madam,'
    },
    async saveChanges() {
      this.sending = true
      this.formData[keys.streetNo] = stripSpaces(this.formData.street + " " + this.formData.number)
      this.formData[keys.zipCity] = stripSpaces(this.formData.zip + " " + this.formData.city)
      let success = false
      const fullName = stripSpaces(`${this.firstName} ${this.lastName}`)
      if (this.newFreelancer) {
        const existingData = this.$store.getters.freelancerData(fullName)
        if (existingData) {
          this.sending = false
          this.nameError = true
          return
        }
        this.formData[keys.name] = fullName
        this.formData[keys.firstName] = stripSpaces(this.firstName)
        this.formData[keys.lastName] = stripSpaces(this.lastName)
        this.formData['Gender'] = this.gender
        this.formData['Title'] = this.autoTitle(this.gender, this.formData[keys.language])
        this.formData['Salutation'] = this.autoSalutation(this.gender, this.formData[keys.language])
        this.formData['Salutation E-Mail'] = this.autoSalutationEmail(this.gender, this.formData[keys.language], stripSpaces(this.firstName))
        this.formData['Timestamp'] = new Date().toISOString().replace('T', ' ').split('.')[0]
        success = await this.$store.dispatch("addFreelancer", this.formData)
        if (success) {
          this.$emit('freelancerCreated', this.formData[keys.name])
        }
      } else {
        success = await this.$store.dispatch("updateFreelancer", this.formData)
      }
      if (success) {
        await this.$store.dispatch("addNote", {
          freelancer: this.newFreelancer ? fullName : this.freelancerName,
          type: this.newFreelancer ? 'add' : 'edit',
          note: ""
        })
        this.dialog = false
      }
      else {
        this.networkError = true
      }
      this.sending = false
    }
  },
  watch: {
    dialog() {
      this.updateData()
    },
    freelancerName() {
      this.updateData()
    },
    firstName() {
      this.nameError = false
    },
    lastName() {
      this.nameError = false
    }
  }
}
</script>
