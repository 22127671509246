<template>
  <v-dialog v-model="dialog" :persistent="uploading" width=920>
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" color="grey darken-3" dark target="_blank">
        Upload document
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        <span class="text-h5 my-2">
          Upload VAT document for {{ freelancerName }}
        </span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-file-input label="VAT document" v-model="fileInput" truncate-length="500" :disabled="uploading">
          </v-file-input>
        </v-container>
      </v-card-text>

      <v-alert v-if="networkError" color="error" dark class="rounded-0">
        Network error
      </v-alert>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="dialog = false" :disabled="uploading">
          Cancel
        </v-btn>
        <v-btn color="blue darken-1" text @click="uploadVATDocument" :disabled="uploading || !fileInput">
          Upload
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { keys } from "../helpers"

export default {
  props: [
    'freelancerName',
  ],
  data: () => ({
    dialog: false,
    fileInput: undefined,
    networkError: false,
    uploading: false,
  }),
  watch: {
    dialog() {
      this.fileInput = undefined
    }
  },
  computed: {
    freelancer() {
      return this.$store.getters.freelancerData(this.freelancerName)
    }
  },
  methods: {
    async uploadVATDocument() {
      this.uploading = true
      this.$store.dispatch('uploadVATDocument', {
        fullName: this.freelancerName,
        firstName: this.freelancer[keys.firstName],
        lastName: this.freelancer[keys.lastName],
        fileInput: this.fileInput
      })
        .finally(() => {
          this.dialog = false
          this.uploading = false
        })
    }
  }
}
</script>
