<template>
  <v-app id="inspire">
    <div v-if="$store.state.loggedIn">
      <v-snackbar v-model="$store.state.errorVisible" color="red" :timeout="-1" :multi-line="true">
        {{ $store.state.errorMessage }}<br>
        Please notify <a href="mailto:pipeline@woodblock.tv">pipeline@woodblock.tv</a>

        <template v-slot:action="{ attrs }">
          <v-btn color="white" text v-bind="attrs" @click="$store.dispatch('hideError')">
            Close
          </v-btn>
        </template>
      </v-snackbar>
      <v-app-bar app clipped-right elevation="3">
        <img :src="require('./assets/woodblock-wordmark.png')" />

        <v-toolbar-title class="mx-2 my-0">
          Suppliers
        </v-toolbar-title>

        <v-spacer />
        <v-text-field v-model="search" label="Search" prepend-icon="mdi-magnify" single-line hide-details dense
          clearable />
        <v-spacer />

        Show...
        <v-checkbox label="Companies" class="mx-2" hide-details v-model="$store.state.showCompanies" />
        <v-checkbox label="Persons" class="mx-2" hide-details v-model="$store.state.showPersons" />
        <v-checkbox label="Staff" class="mx-2" hide-details v-model="$store.state.showStaff" />
        <v-checkbox label="Blocked" class="mx-2 ml-4" hide-details v-model="$store.state.showBlocked" />
        <v-checkbox label="Rates" class="mx-2 mr-6" hide-details v-model="$store.state.showRates" />

        <FreelancerEdit buttonLabel="New Entry" :newFreelancer="true" @freelancerCreated="freelancerCreated" />

        <HelpDialog />

        <v-btn class="ml-2" href="/api/logout" color="grey darken-3" dark alt="Logout">
          Logout
          <v-icon right>
            mdi-logout
          </v-icon>
        </v-btn>
      </v-app-bar>

      <v-main app>
        <Overview :search="search" @selection="selectionChanged" />
      </v-main>

      <v-navigation-drawer app permanent right clipped width="500" color="#f0f0f0">
        <div v-if="selectedFreelancerName">
          <PanelEdit :freelancerName="selectedFreelancerName" @searchAppend="searchAppend"
            @searchReplace="searchReplace" />
        </div>
        <div v-else class="ma-4 text-center grey--text">No freelancer selected</div>
      </v-navigation-drawer>
    </div>

    <div v-else>
      <v-main>
        <v-container fill-height fluid>
          <v-row align="center" justify="center">
            <v-col>
              <v-card width="500" class="pa-2">
                <v-card-title>Login</v-card-title>
                <v-card-actions>
                  <v-btn v-if="$store.state.loginUrl" dark :href="$store.state.loginUrl">
                    Login
                  </v-btn>
                </v-card-actions>
                <v-card-text>
                  <v-progress-circular v-if="!$store.state.loginUrl" indeterminate color="black"></v-progress-circular>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-main>
    </div>
  </v-app>
</template>

<script>
import Overview from "./components/Overview"
import PanelEdit from "./components/PanelEdit.vue"
import FreelancerEdit from "./components/FreelancerEdit.vue"
import HelpDialog from "./components/HelpDialog.vue"
import { keys } from "./helpers"

export default {
  components: {
    Overview,
    PanelEdit,
    FreelancerEdit,
    HelpDialog
  },
  data: () => ({
    search: "",
    selectedFreelancerName: null,
    snackbar: false,
    timeout: 2000
  }),
  methods: {
    selectionChanged: function (row) {
      if (row.length < 1) {
        this.selectedFreelancerName = null
      } else {
        this.selectedFreelancerName = row[0][keys.name]
      }
    },
    searchAppend(searchString) {
      if (this.search.indexOf(searchString) < 0) {
        this.search = (this.search ? this.search + ' ' : '') + searchString
      }
    },
    searchReplace(searchString) {
      this.search = searchString
    },
    freelancerCreated(freelancerName) {
      this.selectedFreelancerName = freelancerName
    }
  },
  mounted() {
    this.$store.dispatch("fetchSession")
  },
}
</script>

<style>
.text-decoration-none:hover {
  text-decoration: underline !important;
}

.v-list-item--active {
  color: rgba(0, 0, 0, 0.87) !important;
}

code {
  background-color: rgba(0, 0, 0, 0.12) !important;
}
</style>
