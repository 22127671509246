<template>
  <v-data-table :headers="headers" :items="viewData" item-key="row" sort-by="Full Name" :footer-props="{
    'items-per-page-options': [20, 50, 100, -1],
  }" :items-per-page="50" :search="search" :loading="$store.getters.loading" loader-height="3" v-model="selected"
    single-select :custom-filter="customFilter" v-on:click:row="select" dense fixed-header height="88vh"
    style="cursor: default" :no-data-text="noDataText">
    <template v-slot:item.notesCount="{ item }">
      <v-chip v-if="item.notesCount" color="light-grey" small>
        {{ item.notesCount }}
      </v-chip>
    </template>
    <template v-slot:item.projectsArray="{ item }">
      <div v-if="item.projectsArray.length">
        <v-chip v-for="project in item.projectsArray.slice(0, 1)" :key="project.id"
          class="custom-chip mt-1 mb-1 mr-1 pa-2 pt-0 pb-0" color="light-grey" small>
          {{ project }}
        </v-chip>
        <span v-if="item.projectsArray.length > 1" class="grey--text"> +{{ item.projectsArray.length - 1 }} </span>
      </div>
    </template>
    <template v-slot:item.niceRate="{ item }">
      <div v-if="item.niceRate">
        <span v-if="$store.state.showRates">
          {{ item.niceRate }}
        </span>
        <span v-else class="grey--text">
          hidden
        </span>
      </div>
    </template>
    <template v-slot:item.tasksArray="{ item }">
      <div v-if="item.tasksArray.length">
        <v-chip v-for="task in item.tasksArray.slice(0, 2)" :key="task"
          class="custom-chip mt-1 mb-1 mr-1 pa-2 pt-0 pb-0" color="light-grey" small>
          {{ task }}
        </v-chip>
        <span v-if="item.tasksArray.length > 2" class="grey--text"> +{{ item.tasksArray.length - 2 }} </span>
      </div>
    </template>
    <template v-slot:item.softwareArray="{ item }">
      <div v-if="item.softwareArray.length">
        <v-chip v-for="software in item.softwareArray.slice(0, 2)" :key="software"
          class="custom-chip mt-1 mb-1 mr-1 pa-2 pt-0 pb-0" color="light-gray" small>
          {{ software }}
        </v-chip>
        <span v-if="item.softwareArray.length > 2" class="grey--text"> +{{ item.softwareArray.length - 2 }} </span>
      </div>
    </template>
  </v-data-table>
</template>

<script>
// import { mapGetters } from "vuex"
import { io as socketIO } from "socket.io-client"
import { keys, stringToArray, splitZipCity, niceRate } from "../helpers"

export default {
  props: ["search"],
  emits: ["selected"],
  watch: {
    selected: function (row) {
      this.$emit("selection", row)
    },
  },
  methods: {
    select: function (row) {
      if (this.selected[0] === row) {
        this.selected = []
      } else {
        this.selected = [row]
      }
    },
    customFilter: function (value, search, item) {
      const parts = search
        .toString()
        .toLowerCase()
        .replaceAll(", ", ",")
        .replaceAll(",", " ")
        .split(" ")
      const matches = parts.filter((part) => {
        if (part[0] == "!") {
          return item.searchSummary.indexOf(part.substring(1)) < 0
        } else {
          return item.searchSummary.indexOf(part) >= 0
        }
      })
      return matches.length == parts.length
    },
  },
  data() {
    return {
      noDataText: "No data: Make sure you have access to the suppliers Google sheet.",
      socket: null,
      selected: [],
      headers: [
        {
          text: "Name",
          align: "start",
          sortable: true,
          value: keys.name,
          divider: true,
          resizable: true,
          width: 200,
        },
        {
          text: "Projects",
          align: "start",
          sortable: true,
          value: "projectsArray",
          divider: true,
        },
        {
          text: "Level",
          align: "start",
          sortable: true,
          value: keys.level,
          divider: true,
          width: 95,
        },
        {
          text: "Rate",
          align: "end",
          sortable: true,
          value: "niceRate",
          divider: true,
          width: 85,
        },
        {
          text: "Notes",
          align: "center",
          sortable: true,
          value: "notesCount",
          divider: true,
          width: 85,
        },
        {
          text: "Tasks",
          align: "start",
          sortable: false,
          value: "tasksArray",
          divider: true,
        },
        {
          text: "Software",
          align: "start",
          sortable: false,
          value: "softwareArray",
          divider: true,
        },
        {
          text: "City",
          align: "start",
          sortable: true,
          value: "city",
          divider: false,
        },
      ],
    }
  },
  computed: {
    // ...mapGetters([
    //   "overview"
    // ]),
    viewData() {
      return this.$store.getters.overview.map(row => {
        const [zip, city] = splitZipCity(row[keys.zipCity])
        return {
          ...row,
          city,
          projectsArray: stringToArray(row[keys.projects]),
          tasksArray: stringToArray(row[keys.tasks]),
          softwareArray: stringToArray(row[keys.software]),
          niceRate: niceRate(row[keys.rate])
        }
      })
    }
  },
  mounted() {
    this.socket = socketIO()
    this.socket.on('update', payload => {
      if (payload === 'vat') {
        this.$store.dispatch("fetchVATDocuments")
      }
      if (payload === 'nda') {
        this.$store.dispatch("fetchNDAs")
      }
      if (payload === 'note') {
        this.$store.dispatch("fetchNotes")
      }
      if (payload === 'freelancer') {
        this.$store.dispatch("fetchFreelancers")
      }
    })
    this.$store.dispatch("fetchDataLoop")
    document.documentElement.style.setProperty('--toolbarHeight', '50px')
  },
}
</script>

<style scoped>
.custom-chip:before {
  opacity: 0.04;
}
</style>
